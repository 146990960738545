@import url(https://fonts.googleapis.com/css?family=Source+Serif+Pro);
@import url(https://fonts.googleapis.com/css?family=Work+Sans);

.country {
  font-weight: bold;
  color: #111;
  padding: 20px 20px 0px 20px;
}
.organization-attributes {
  margin: 0 0 20px 0;
}
.incident-count {
  padding: 10px 20px;
  font-size: 12px;
}
.fatalities-count {
  padding: 10px 20px;
  font-size: 12px;
}
.pi-chart {
  height: 50px;
  background: #FFF0F0;
  margin: 10px 20px 0 20px;
}
.actors-count {
  padding: 20px;
  font-size: 12px;
  color: #555;
  max-height: 588px;
  overflow: hidden;
}
.actors-count .actor {
  background: #f9f9f9;
  padding: 12px 10px 10px 10px;
  position: relative;
  margin-bottom: 5px;
}
.percent-bar {
  background: #FFF0F0;
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 100%;
}
.bar {
  background: #ED1B23;
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  min-width: 3px;
}
.international-orgs {
  padding: 20px;
  height: 300px;
}

.conflict-event-filters {
  height: 50%;
}
.loading {
  background: #FE8F12;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  color: #111;
}
.loader {
  margin-top: 40vh;
  text-align: center;
  font-weight: 700;
  font-size: 30px;
}
.loader .ant-spin-dot-item {
  background-color: #111;
}
.loader .ant-spin-text {
  color: #111;
}
.mapboxgl-ctrl-bottom-left {
  margin-left: 280px;
}
.mapboxgl-ctrl-attrib {
  right: 290px;
  bottom: 5px;
  position: fixed;
}
.filters {
  width: 280px;

  height: 100%;
  background: #fff;
  box-shadow: 0 0 16px 4px rgba(20,33,61,.2);
}
.filters .content {
  position: relative;
  height: 100%;
}

@media(max-width: 700px) {
  .filters {
    left: 0;
    width: 150px;
    top: 0;
    height: 100vh;
  }
}
.logo {
  width: 20px;
  display: block;
  height: 20px;
  background: url(/static/media/pi-logo.2dc2d253.png) no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 20px;
  display: none;
}
@media(max-width: 700px) {
  .filters .header {
    top: 0;
    width: 110px;
  }
}
.filter-header button {
  width: 100%;
  display: block;
  border: none;
  background: #FE8F12;
  padding: 15px 20px;
  color: #000;
  text-align: left;
  font-size: 16px;
  outline: none;
  font-weight: bold;
  cursor: pointer;
}
.filter-content {
  padding: 10px 20px;
}
.filters label {
  text-transform: uppercase;
  font-size: 16px;
  display: block;
  margin: 0 0 3px 0;
  color: #999;
}
.filters .regions {
  color: #777;
}
.filters select {
  width: 100%;
  color: #444;
  background: #eee;
  font-size: 14px;
  border: none;
  height: 30px;
}
.filters .orgs {
  color: #999;
}
.filters .conflict {
  color: #999;
}


.mask {
  width: 100%;
  height: 100%;
  background: rgba(255,255,255, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: none;
}
.mask .text {
  color: #111;
  font-size: 30px;
  text-align: right;
  position: fixed;
  width: 80%;
  top: 20%;
  padding: 0 0 10px 0;
  left: 40px;
  border-bottom: 2px solid #333;
}


.pi-menu {
  margin-bottom: 15px;
}
.pi-menu-content {
  overflow-y: scroll;
  max-height: 900px;
}
.pi-menu-content.hidden {
  display:none;
}
.pi-menu button {
  display: block;
  width: 100%;
  border: none;
  font-size: 14px;
  background: #eee;
  color: #444;
  padding: 10px;
  margin-top: 1px;
  cursor: pointer;
  outline: none;
}
.pi-menu button:hover {
  background: #f9f9f9;
}
.pi-menu button.active {
  background: #FE8F12;
  color: #fff;
}
.conflict .pi-menu button.active {
  background: #ED1B23;
  color: #fff;
}
.actors .pi-menu button.active {
  background: #ED1B23;
  color: #fff;
}
.pi-menu-header {
  font-size: 14px;
  padding: 3px 0;
  color: #777;
}



.conflict-details {
  z-index: 110;
}
.conflict-details .item {
  line-height: 1.3;
  font-size: 15px;
  padding: 10px 20px 10px 0;
  border-top: 3px solid #ED1B23;
  text-align: left;
  margin: 0 0 20px 0;
  overflow: hidden;
  color: #111;
}

.org-details {
  z-index: 90;
}
.org-details h2 {
  /* color: #FE8F12; */
  font-size: 20px;
}
.org-details h3 {
  font-size: 18px;
  /* font-weight: bold; */
  color: #FE8F12;
}
.org-details .title {
  margin: 20px 0 20px 0;
  font-size: 18px;
}
.org-details .item {
  line-height: 1.3;
  font-size: 15px;
  padding: 10px 20px 10px 0;
  border-top: 3px solid #FE8F12;
  text-align: left;
  margin: 0 0 20px 0;
  overflow: hidden;
  color: #111;
}
.org-details .item a {
  color: #FE8F12;
  text-decoration: none;
}
.org-details .item a:hover {
  opacity: 0.5;
}

.org-list-item {
  /* margin-bottom: 1rem; */
  padding: 1rem;
  cursor: pointer;
}
.org-list-item:hover {
  box-shadow: 0px 0px 4px #FE8E11;
  border-radius: 6px;
}
.org-list-item-label {
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
}
.org-list-item-description {
  font-size: 14.4px;
  line-height: 130%;
}

.organization-chart {
  background: #f9f9f9;
  height: 300px;
  margin: 0 0 40px 0;
  font-size: 14px;
  color: #777;
}
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-webkit-keyframes fadeOut {
    from { opacity: 1; }
    to   { opacity: 0; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to   { opacity: 0; }
}

.conflict-switch,
.org-switch {
  font-size: 13px;
  margin: 0 0 20px 0;
  color: #444;
}
.conflict-switch .ant-switch {
  background-color: #ED1B23;
}
.org-switch .ant-switch {
  background-color: #FE8F12;
}

.conflict-header button {
  background: #ED1B23;
  color: #fff;
}
.axis-controls {
  margin: 10px 0;
  text-align: right;
}
.axis-button {
  border: none;
  background: none;
  cursor: pointer
}

.orgs {
  height: 50%;
  display: flex;
  flex-direction: column;
}
.orgs .filter-content{
  max-height: 100%;
  overflow: auto;
}
.pi-map {
  width: 100%;
  height: 100%;
}
.zoom-cntrl {
  left: 4rem;
  top: 1rem;
  position: absolute;
}

.profile {
  z-index: 100;
  width: 550px !important;
}
.profile-table {
  margin-bottom: 1rem;
  width: 100%;
}
.profile-table tr {
  border-bottom: 1px solid #eee;
}
.profile-table td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  vertical-align: top;
}
.profile-table td:first-child {
  font-weight: bold;
  width: 40%;
}
/* Global styles */
body {
  padding: 0;
  margin: 0;
  font-family: "Work Sans", sans-serif;
  color: #000;
}

h1, h2, h3 {
  font-family: "Source Serif Pro", serif;
}

h2 {
  font-size: 18px;
  font-weight: bold;
}

.btn-close {
  text-align: right;
}
.btn-close button {
  background: none;
  color: #000;
  border: none;
  padding: 0;
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}

/* Global layout */
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app {
  position: relative;
  flex: 1 1;
  overflow: auto;
  display: flex;
}

.info-panel {
  position: absolute;
  height: 100%;
  overflow-y: scroll;
  background: rgba(255,255,255,1);
  width: 450px;
  right: 0;
  top: 0;
  padding: 1rem 1rem 1rem;
  box-shadow: 0 0 16px 4px rgba(20,33,61,.2);
  -webkit-animation: fadein 0.1s;
          animation: fadein 0.1s;
}

/* Page header */
header {
  display: flex;
  padding: 1rem 1rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}

header .header-block {
  display: flex;
  align-items: center; 
}

.header-block h1 {
  margin: 0;
  padding: 0;
}

.header-block .summary {
  margin-left: 2rem;
  max-width: 500px;
}

.header-tools {
  
}
