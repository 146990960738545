@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro');
@import url('https://fonts.googleapis.com/css?family=Work+Sans');

/* Global styles */
body {
  padding: 0;
  margin: 0;
  font-family: "Work Sans", sans-serif;
  color: #000;
}

h1, h2, h3 {
  font-family: "Source Serif Pro", serif;
}

h2 {
  font-size: 18px;
  font-weight: bold;
}

.btn-close {
  text-align: right;
}
.btn-close button {
  background: none;
  color: #000;
  border: none;
  padding: 0;
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}

/* Global layout */
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app {
  position: relative;
  flex: 1;
  overflow: auto;
  display: flex;
}

.info-panel {
  position: absolute;
  height: 100%;
  overflow-y: scroll;
  background: rgba(255,255,255,1);
  width: 450px;
  right: 0;
  top: 0;
  padding: 1rem 1rem 1rem;
  box-shadow: 0 0 16px 4px rgba(20,33,61,.2);
  animation: fadein 0.1s;
}

/* Page header */
header {
  display: flex;
  padding: 1rem 1rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}

header .header-block {
  display: flex;
  align-items: center; 
}

.header-block h1 {
  margin: 0;
  padding: 0;
}

.header-block .summary {
  margin-left: 2rem;
  max-width: 500px;
}

.header-tools {
  
}