.profile {
  z-index: 100;
  width: 550px !important;
}
.profile-table {
  margin-bottom: 1rem;
  width: 100%;
}
.profile-table tr {
  border-bottom: 1px solid #eee;
}
.profile-table td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  vertical-align: top;
}
.profile-table td:first-child {
  font-weight: bold;
  width: 40%;
}